import { Theme, alpha } from "@mui/material"

const Button: NonNullable<Theme["components"]>["MuiButton"] = {
  defaultProps: {
    variant: "contained",
    color: "secondary",
    disableRipple: true,
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      borderRadius: 8,

      "& .MuiButton-endIcon svg": {
        height: 17,
        width: 17,
      },
      ...(ownerState.color === "secondary" &&
        theme.palette.mode === "dark" &&
        ownerState.variant === "contained" && {
          backgroundColor: theme.palette.tertiary.main,
          color: theme.palette.tertiary.contrastText,
        }),
      ...(ownerState.color === "tertiary" &&
        ownerState.variant === "text" && {
          color: theme.palette.tertiary.dark,
        }),
      ...(ownerState.color === "tertiary" &&
        ownerState.variant === "contained" &&
        theme.palette.mode === "dark" && {
          backgroundColor: "#A0A0A0",
        }),
      ...(ownerState.variant === "text" &&
        ownerState.color === "secondary" && {
          color: theme.palette.mode === "dark" ? "#FFF" : "#000",
        }),
      ":hover": {
        ...(ownerState.color === "primary" &&
          ownerState.variant === "contained" && {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
          }),
        ...(ownerState.color === "secondary" &&
          ownerState.variant === "contained" && {
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.tertiary.contrastText,
          }),
        ...(ownerState.color === "tertiary" &&
          ownerState.variant === "contained" && {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          }),
        ...(ownerState.color === "tertiary" &&
          ownerState.variant === "text" && {
            color: theme.palette.primary.main,
          }),
        ...(ownerState.color === "primary" &&
          ownerState.variant === "contained" &&
          theme.palette.mode === "dark" && {
            backgroundColor: alpha(theme.palette.primary.main, 0.7),
            color: theme.palette.primary.contrastText,
          }),
        ...(ownerState.color === "secondary" &&
          ownerState.variant === "contained" &&
          theme.palette.mode === "dark" && {
            backgroundColor: alpha(theme.palette.tertiary.main, 0.7),
          }),
        ...(ownerState.color === "tertiary" &&
          ownerState.variant === "contained" &&
          theme.palette.mode === "dark" && {
            backgroundColor: alpha("#A0A0A0", 0.7),
            color: theme.palette.tertiary.contrastText,
          }),
        ...(ownerState.color === "kwalee" &&
          ownerState.variant === "contained" &&
          theme.palette.mode === "dark" && {
            backgroundColor: alpha(theme.palette.kwalee.main, 0.7),
          }),
        ...(ownerState.color === "primary" &&
          ownerState.variant === "outlined" && {
            backgroundColor: alpha(theme.palette.primary.main, 0.4),
          }),
      },
    }),
  },
}

export default Button
