/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../app/services/graphql/types.generated';

import { api } from '@/app/services/graphql/api';
export type GetCpiListQueryVariables = Types.Exact<{
  game_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  created_at_range?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.Scalars['String']['input']>;
  search_name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_no: Types.Scalars['Int']['input'];
  internal_spam?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetCpiListQuery = { __typename?: 'Query', cpi_total_objects?: number, cpi?: Array<{ __typename?: 'CPIOutputType', id: string, name: string, platform: string, game_id: number, rejection_reason?: string, fb_app_id: string, app_url: string, status: string, created_at: any, updated_at: any, internal_spam?: boolean, games?: { __typename?: 'GameOutputType', name: string, icon_url?: string, ios_store_link?: string, android_store_link?: string, kwalee_sdk_key: Array<{ __typename?: 'KwaleeSdkKeysOutputType', is_kwalee_sdk_validated: boolean }> }, cpi_fb_campaign: Array<{ __typename?: 'CPIFBCampaignOutputType', duration?: number, fb_campaign_status?: string, started_at: any, status?: string, region: string, id: string }>, user?: { __typename?: 'UserOutputType', user_type: string } }> };

export type RequestChangesHistoryQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type RequestChangesHistoryQuery = { __typename?: 'Query', request_changes?: Array<{ __typename?: 'RequestChangesOutputType', id: string, request_changes?: string, updated_at: any }> };

export type GetCpiFormDetailByIdQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCpiFormDetailByIdQuery = { __typename?: 'Query', cpi?: Array<{ __typename?: 'CPIOutputType', id: string, game_id: number, name: string, platform: string, fb_app_id: string, app_url: string, status: string, app_store_id?: string, rejection_reason?: string, updated_at: any, user?: { __typename?: 'UserOutputType', user_type: string } }> };

export type GetCpiDetailsQueryVariables = Types.Exact<{
  game_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  status?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCpiDetailsQuery = { __typename?: 'Query', cpi?: Array<{ __typename?: 'CPIOutputType', id: string, status: string, name: string, cpi_fb_campaign: Array<{ __typename?: 'CPIFBCampaignOutputType', duration?: number, fb_campaign_status?: string, started_at: any, status?: string, region: string, id: string }> }> };

export type GetCpiByIdQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCpiByIdQuery = { __typename?: 'Query', cpi?: Array<{ __typename?: 'CPIOutputType', id: string, name: string, platform: string, game_id: number, organisation_id?: number, rejection_reason?: string, fb_app_id: string, app_url: string, status: string, created_at: any, updated_at: any, games?: { __typename?: 'GameOutputType', name: string, icon_url?: string, ios_store_link?: string, android_store_link?: string, kwalee_sdk_key: Array<{ __typename?: 'KwaleeSdkKeysOutputType', is_kwalee_sdk_validated: boolean }> }, cpi_fb_campaign: Array<{ __typename?: 'CPIFBCampaignOutputType', duration?: number, fb_campaign_status?: string, started_at: any, status?: string, region: string, id: string }>, user?: { __typename?: 'UserOutputType', user_type: string } }> };

export type GetCpiTestTitleQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetCpiTestTitleQuery = { __typename?: 'Query', cpi?: Array<{ __typename?: 'CPIOutputType', id: string, name: string }> };

export type GetCpiVideosQueryVariables = Types.Exact<{
  cpi_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCpiVideosQuery = { __typename?: 'Query', cpi_ad_videos?: Array<{ __typename?: 'CPIAdVideosOutputType', cpi_id: number, ad_video_url: string, thumbnail_ad_image_url: string, video_order: number, id: string }> };

export type GetAdAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAdAccountsQuery = { __typename?: 'Query', fb_ad_accounts?: Array<{ __typename?: 'FBAdAccountOutputType', id: string, name: string, fb_ad_account_id: string, fb_page_id: string, test_type: string, status: string, created_at: any, updated_at: any }> };


export const GetCpiListDocument = `
    query GetCpiList($game_id: Int, $created_at_range: String, $status: String, $search_name: String, $page_size: Int, $page_no: Int!, $internal_spam: Boolean) {
  cpi(
    filters: {game_id: $game_id, created_at_range: $created_at_range, status: $status, search_name: $search_name, internal_spam: $internal_spam}
    page_size: $page_size
    page_no: $page_no
    sort_by: "updated_at"
    sort_dir: "desc"
  ) {
    id
    name
    platform
    game_id
    rejection_reason
    games {
      name
      icon_url
      ios_store_link
      android_store_link
      kwalee_sdk_key {
        is_kwalee_sdk_validated
      }
    }
    fb_app_id
    app_url
    status
    created_at
    updated_at
    cpi_fb_campaign {
      duration
      fb_campaign_status
      started_at
      status
      region
      id
    }
    user {
      user_type
    }
    internal_spam
  }
  cpi_total_objects(
    filters: {game_id: $game_id, created_at_range: $created_at_range, status: $status, search_name: $search_name, internal_spam: $internal_spam}
  )
}
    `;
export const RequestChangesHistoryDocument = `
    query RequestChangesHistory($id: Int) {
  request_changes(filters: {cpi_id: $id}, sort_dir: "desc", sort_by: "updated_at") {
    id
    request_changes
    updated_at
  }
}
    `;
export const GetCpiFormDetailByIdDocument = `
    query GetCpiFormDetailById($id: Int) {
  cpi(filters: {id: $id}) {
    id
    game_id
    name
    platform
    fb_app_id
    app_url
    status
    app_store_id
    rejection_reason
    updated_at
    user {
      user_type
    }
  }
}
    `;
export const GetCpiDetailsDocument = `
    query GetCpiDetails($game_id: Int, $status: String, $sort_dir: String, $sort_by: String, $limit: Int) {
  cpi(
    filters: {game_id: $game_id, status: $status}
    sort_dir: $sort_dir
    sort_by: $sort_by
    page_size: $limit
  ) {
    id
    status
    name
    cpi_fb_campaign {
      duration
      fb_campaign_status
      started_at
      status
      region
      id
    }
  }
}
    `;
export const GetCpiByIdDocument = `
    query GetCpiById($id: Int) {
  cpi(filters: {id: $id}) {
    id
    name
    platform
    game_id
    organisation_id
    rejection_reason
    games {
      name
      icon_url
      ios_store_link
      android_store_link
      kwalee_sdk_key {
        is_kwalee_sdk_validated
      }
    }
    fb_app_id
    app_url
    status
    created_at
    updated_at
    cpi_fb_campaign {
      duration
      fb_campaign_status
      started_at
      status
      region
      id
    }
    user {
      user_type
    }
  }
}
    `;
export const GetCpiTestTitleDocument = `
    query GetCpiTestTitle($id: Int!) {
  cpi(filters: {id: $id}) {
    id
    name
  }
}
    `;
export const GetCpiVideosDocument = `
    query GetCpiVideos($cpi_id: Int) {
  cpi_ad_videos(filters: {cpi_id: $cpi_id}) {
    cpi_id
    ad_video_url
    thumbnail_ad_image_url
    video_order
    id
  }
}
    `;
export const GetAdAccountsDocument = `
    query GetAdAccounts {
  fb_ad_accounts {
    id
    name
    fb_ad_account_id
    fb_page_id
    test_type
    status
    created_at
    updated_at
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCpiList: build.query<GetCpiListQuery, GetCpiListQueryVariables>({
      query: (variables) => ({ document: GetCpiListDocument, variables })
    }),
    RequestChangesHistory: build.query<RequestChangesHistoryQuery, RequestChangesHistoryQueryVariables | void>({
      query: (variables) => ({ document: RequestChangesHistoryDocument, variables })
    }),
    GetCpiFormDetailById: build.query<GetCpiFormDetailByIdQuery, GetCpiFormDetailByIdQueryVariables | void>({
      query: (variables) => ({ document: GetCpiFormDetailByIdDocument, variables })
    }),
    GetCpiDetails: build.query<GetCpiDetailsQuery, GetCpiDetailsQueryVariables | void>({
      query: (variables) => ({ document: GetCpiDetailsDocument, variables })
    }),
    GetCpiById: build.query<GetCpiByIdQuery, GetCpiByIdQueryVariables | void>({
      query: (variables) => ({ document: GetCpiByIdDocument, variables })
    }),
    GetCpiTestTitle: build.query<GetCpiTestTitleQuery, GetCpiTestTitleQueryVariables>({
      query: (variables) => ({ document: GetCpiTestTitleDocument, variables })
    }),
    GetCpiVideos: build.query<GetCpiVideosQuery, GetCpiVideosQueryVariables | void>({
      query: (variables) => ({ document: GetCpiVideosDocument, variables })
    }),
    GetAdAccounts: build.query<GetAdAccountsQuery, GetAdAccountsQueryVariables | void>({
      query: (variables) => ({ document: GetAdAccountsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCpiListQuery, useLazyGetCpiListQuery, useRequestChangesHistoryQuery, useLazyRequestChangesHistoryQuery, useGetCpiFormDetailByIdQuery, useLazyGetCpiFormDetailByIdQuery, useGetCpiDetailsQuery, useLazyGetCpiDetailsQuery, useGetCpiByIdQuery, useLazyGetCpiByIdQuery, useGetCpiTestTitleQuery, useLazyGetCpiTestTitleQuery, useGetCpiVideosQuery, useLazyGetCpiVideosQuery, useGetAdAccountsQuery, useLazyGetAdAccountsQuery } = injectedRtkApi;

